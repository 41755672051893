.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.ddlist {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
}

.text-end {
    text-align: end;
}

.theme-color {
    color: #E48CA3;
}

.theme-bg-color {
    background-color: #801818 !important;
}
.headertext {
    color: #fafafa !important
}
.theme-bglight-color {
    background-color: lightpink !important;
}

select>option:hover {
    background-color: #E48CA3 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.btn-theme:hover {
    background-color: #E48CA3;
    border-color: #E48CA3;
}

.btn-theme:hover {
    color: #fff;
    background-color: #E48CA3;
    border-color: #E48CA3;
}

.btn-theme {
    background-color: #E48CA3;
    border-color: #E48CA3;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
}

.btn-outline-theme:hover {
    color: #fff;
    background-color: #E48CA3;
    border-color: #E48CA3;
}

.btn-outline-theme {
    color: #E48CA3;
    background-color: #fff;
    background-color: transparent;
}

.hheadinggrey {
    font-size: 18px;
    font-family: 'Oxygen', sans-serif;
    border-bottom: 2px solid #ec4863;
    padding: 5px;
    padding-left: 2%;
    background-color: #ededed;
}

#pcoded > div.pcoded-container.navbar-wrapper > div.pcoded-main-container > div > div > div > div > div.hheadinggrey.mt-3 > span > i {
    font-size: 20px;
    color: #ec4863;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    margin-right: 5px;
}

.theme-name-color {
    color: #c57187;
}

.hcandheading {
    font-weight: bold;
    font-family: "Oxygen";
}

.mb-0 {
    margin-bottom: 0px !important;
}

.icon-bg {
    background-color: #fff !important;
    border-radius: 100% !important;
    padding: 7px !important;
}

.headerbtn {
    font-size: 16px;
    padding: 3px 5px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.profile-heading {
    background-color: #EC4863;
    color: fff;
    padding: 1px;
    text-align: center;
}

.profile-heading-text {
    letter-spacing: 2px;
    margin-top: 5px;

}

.imgsize-homepage {
    width: 150px;
    height: 180px;
}

#card:hover {
    transform: scale(1.05);
    box-shadow: rgb(255 0 0 / 24%) 0px 54px 55px, rgb(255 0 0 / 24%) 0px -12px 30px, rgb(255 0 0 / 24%) 0px 4px 6px, rgb(255 0 0 / 24%) 0px 12px 13px, rgb(255 0 0 / 24%) 0px -3px 5px;
}

#body-row {
    margin-left: 0;
    margin-right: 0;
}


.fits {
    flex-basis: fit-content;
}

#card-body-p {
    padding: 0.88rem !important;
}

.card-left-img-max-h{
    max-height: 215px;
}
.ellipsis-text {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
    width: 200px; /* Adjust the width as needed */
}
 .fw-500{
    font-weight: 500;
 }
.card-body-bg-color{
    background-color: antiquewhite;
}

.cursor-pointer {
    cursor: pointer;
}

.home-filter-header-isShow {
    display: none;
}

.home-filter-sidebar-isShow {
    display: block;
}

@media screen and (max-width: 991px) {
    .home-filter-header-isShow {
        display: block;
    }

    .home-filter-sidebar-isShow {
        display: none;
    }
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

/* Add Candidate Css Start */
.flexer,
.progress-indicator {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.no-flexer,
.progress-indicator.stacked {
    display: block;
}

.no-flexer-element {
    -ms-flex: 0;
    -webkit-flex: 0;
    -moz-flex: 0;
    flex: 0;
}

.flexer-element,
.progress-indicator>li {
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
}

.progress-indicator {
    margin: 0;
    padding: 0;
    font-size: 100%;
    text-transform: uppercase;
    margin-bottom: 1em;
}

.progress-indicator>li {
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: #bbb;
    display: block;
}

.progress-indicator>li:hover {
    color: #6f6f6f;
}


.progress-indicator>li .bubble {
    border-radius: 1000px;
    width: 20px;
    height: 50px;
    background-color: #bbb;
    display: block;
    margin: 0 auto 0.5em auto;
    border-bottom: 1px solid #888888;
}

.progress-indicator>li .bubble:before,
.progress-indicator>li .bubble:after {
    display: block;
    position: absolute;
    top: 9px;
    width: 100%;
    height: 3px;
    content: '';
    background-color: #bbb;
}

.progress-indicator>li .bubble:before {
    left: 0;
}

.progress-indicator>li .bubble:after {
    right: 0;
}

.progress-indicator>li.completed {
    color: #65d074;
}

.progress-indicator>li.completed .bubble {
    background-color: #b0e12f;
    color: #000;
    border-color: #247830;
}

.progress-indicator>li.completed .bubble:before,
.progress-indicator>li.completed .bubble:after {
    background-color: #b0e12f;
    border-color: #247830;
}

.progress-indicator>li.current {
    color: #000;
}

.progress-indicator>li.current .bubble {
    background-color: #ec4863;
    color: #000;
    border-color: #ec4863;
}

.progress-indicator>li.current .bubble:before,
.progress-indicator>li.completed .bubble:after {
    background-color: #b0e12f;
    border-color: #247830;
}


.progress-indicator>li.active {
    color: #337AB7;
}

.progress-indicator>li.active .bubble {
    background-color: #337AB7;
    color: #337AB7;
    border-color: #122a3f;
}

.progress-indicator>li.active .bubble:before,
.progress-indicator>li.active .bubble:after {
    background-color: #337AB7;
    border-color: #122a3f;
}

.progress-indicator>li a:hover .bubble {
    background-color: #7d7f7a;
    color: #333;
    border-color: #1f306e;
}

.progress-indicator>li a:hover .bubble:before,
.progress-indicator>li a:hover .bubble:after {
    background-color: #7d7f7a;
    border-color: #1f306e;
}

.progress-indicator>li.danger .bubble {
    background-color: #d3140f;
    color: #d3140f;
    border-color: #440605;
}

.progress-indicator>li.danger .bubble:before,
.progress-indicator>li.danger .bubble:after {
    background-color: #d3140f;
    border-color: #440605;
}

.progress-indicator>li.warning .bubble {
    background-color: #edb10a;
    color: #edb10a;
    border-color: #5a4304;
}

.progress-indicator>li.warning .bubble:before,
.progress-indicator>li.warning .bubble:after {
    background-color: #edb10a;
    border-color: #5a4304;
}

.progress-indicator>li.info .bubble {
    background-color: #5b32d6;
    color: #5b32d6;
    border-color: #25135d;
}

.progress-indicator>li.info .bubble:before,
.progress-indicator>li.info .bubble:after {
    background-color: #5b32d6;
    border-color: #25135d;
}

.progress-indicator.stacked>li {
    text-indent: -10px;
    text-align: center;
    display: block;
}

.progress-indicator.stacked>li .bubble:before,
.progress-indicator.stacked>li .bubble:after {
    left: 50%;
    margin-left: -1.5px;
    width: 3px;
    height: 100%;
}

.progress-indicator.stacked .stacked-text {
    position: relative;
    z-index: 10;
    top: 0;
    margin-left: 60% !important;
    width: 45% !important;
    display: inline-block;
    text-align: left;
    line-height: 1.2em;
}

.progress-indicator.stacked>li a {
    border: none;
}

.progress-indicator.custom-complex {
    background-color: #f1f1f1;
    padding: 10px 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.progress-indicator.custom-complex>li .bubble {
    height: 22px;
    width: 99%;
    border-radius: 2px;
    box-shadow: inset -5px 0 12px rgba(0, 0, 0, 0.2);
}

.progress-indicator.custom-complex>li .bubble:before,
.progress-indicator.custom-complex>li .bubble:after {
    display: none;
}

.progress-indicator.custom-complex>li i {
    font-size: 18px;
}

.progress-indicator.custom-complex>li>a {
    color: #bbb;
    text-decoration: none;
}

.progress-indicator.custom-complex>li>a:hover {
    color: #6f6f6f;
    text-decoration: none;
}

.progress-indicator.custom-complex>li.completed>a {
    color: #333;
}

.btn-is-disabled {
    pointer-events: none;
    /* Disables the button completely. Better than just cursor: default; */
}

@media handheld,
screen and (max-width: 400px) {
    .progress-indicator {
        font-size: 80%;
    }
}

/* Progress End */
/* Form Start */


.hindifont {
    font-size: 16px;
    font-weight: normal;
    color: #ec4863;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

/* Add Candidate Css End */


.listheader {
    border-bottom: 2px solid #b0e12f;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hgrbtn {
    background-color: #b0e12f;
    color: #000;
}

.perbox {
    background-color: #ededed;
    padding: 10px;
}

.dashtitle {
    font-size: 18px;
    font-family: 'Oxygen', sans-serif;
    padding: 5px;
    padding-left: 1%;
    background-color: #ededed;
    margin-top: 20px;
}

.dashboard {
    border: 2px solid #ec4863;
    padding-top: 3%;
    margin-top: 30px;
    -webkit-box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 75%);
}

.hsignbtn {
    background-color: #b0e12f;
    color: #000;
}


.search {
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);

}

.search input {

    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;


}


.search input:focus {

    box-shadow: none;
    border: 2px solid;


}

.search .fa-search {

    position: absolute;
    top: 20px;
    left: 16px;

}

.search button {

    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    /* background: blue; */

}


.d-ib {
    display: inline-block;
}